









































































.card
  width 100vw
  max-width 400px

