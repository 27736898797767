.padding {
  width: 70px;
  height: 40px;
  margin-top: 20px;
}
.row {
  margin-top: 16px;
  width: 100%;
  background: #fefefe;
}
/*# sourceMappingURL=src/pages/quote-builder/line-entry.css.map */