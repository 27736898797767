.empty-state-wrapper {
  height: 300px;
  display: grid;
}
.bar {
  position: relative;
  z-index: 10;
}
.container {
  max-width: 1024px;
  margin: auto;
  min-height: calc(100vh - 128px);
}
/*# sourceMappingURL=src/pages/quote-builder/index.css.map */